export default function() {
  /* 表格列头 */
  return [
    { label: "序号", type: "index", width: 70 },
    { label: "词条", prop: "title", width: 200, slots: "title" },
    { label: "图片", prop: "img", slots: "img", width: 90 },
    { label: "词条解释/文献", prop: "detail", width: 300 },

    { label: "出处", prop: "name", width: 200 },
    // { label: "年份", prop: "year", width: 80 },
    { label: "作者", prop: "address", width: 160 },
    // { label: "类型", prop: "type", slots: "type" },
    { label: "创建人", prop: "phone", width: 150 },
    { label: "审核", prop: "recommend", slots: "recommend", width: 90 },
    // {
    //   label: "创建时间",
    //   prop: "createTime",
    //   width: 180,
    //   // slots: "state",
    // },
    {
      label: "操作",
      prop: "operation",
      width: 150,
      slots: "operation",
      // fixed: "right",
    },
  ];
}
