/* 词条相关接口 */
import axios from "axios";
import { request } from "@/utils/request";

// 查询数据
export function selectWords(data) {
  return request({
    url: `/admin/articleWords/select`,
    method: "POST",
    data,
  });
}

// 新增数据
export function addWords(data) {
  return request({
    url: `/admin/articleWords/add`,
    method: "POST",
    data,
  });
}

/* 修改数据 */
export function updateWords(data) {
  return request({
    url: `/admin/articleWords/update`,
    method: "POST",
    data,
  });
}

// 删除数据
export function deleteWords(data) {
  return request({
    url: `/admin/articleWords/delete`,
    method: "POST",
    data,
  });
}

/* 是否上线 */
export function updateRecommend(data) {
  return request({
    url: `/admin/articleWords/updateRecommend`,
    method: "POST",
    data,
  });
}
